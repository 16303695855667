import React, { useEffect, useState, useCallback } from "react";
import { Trans, I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import Photo from "../components/Photo";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import "../styles/purchase.scss";
import "../styles/freetrial.scss";
import "../styles/common_style.scss";
import { getCountry } from '../services/country-service';
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getRegisteredUserByEmail, createTrialUserInfo } from "../services/tenant-identity-service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Helmet } from "react-helmet";
import { MESSAGE } from '../constant/message-enum';
import { getMessages, getFieldConsoleMessages, getValueMessages } from '../services/language-service';
import * as uuid from "uuid";
import { FIELD_NAME } from '../constant/field-name-enum';
import { EventTypeEnum } from '../constant/entity-type-enum';

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GEOLOCATION_URL = process.env.GEOLOCATION_URL;
const ENGLISH_LANGUAGE_GUID = process.env.ENGLISH_LANGUAGE_GUID;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // width: '800px',
    // padding: '48px'
  }
};

const alertBoxStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    // padding: '48px',
  }
};

const initialFormValue = {
  fullName: "",
  companyName: "",
  countryGuid: uuid.NIL,
  email: "",
  mobileNo: ""
}

const Purchase = () => {
  const { language } = React.useContext(I18nextContext);
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/purchase/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/purchase/`;

  const canonicalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation();

  const [alert_message, setAlertMessage] = useState('');
  const [formValues, setformValues] = useState(initialFormValue);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModelOpen] = useState(false);
  const [checked, setCheckBoxValue] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isLoadingCountry, setCountryLoading] = useState(false);
  const [isAlertModalOpen, setAlertModelOpen] = useState(false);
  const [geoLocationCountryCode, setGeoLocationCountryCode] = useState("");
  const [geoLocationCountryGuid, setGeoLocationCountryGuid] = useState(uuid.NIL);
  const [selected_dial_code, setDialCode] = useState("");
  const [isFreeTrialDataSubmitted, setFreeTrialDataSubmited] = useState(false);

  const handleAlertModalClose = async (event) => {
    setAlertModelOpen(false);

    if (isFreeTrialDataSubmitted) {
      let formValue = JSON.parse(JSON.stringify(initialFormValue));
      formValue.mobileNo = geoLocationCountryCode;
      formValue.countryGuid = geoLocationCountryGuid;
      setformValues(formValue);
    }
  }

  const getLanguageGuidByCode = async (currentLanguageCode) => {
    let languageGuid = ENGLISH_LANGUAGE_GUID;
    let langFromLocalStore = JSON.parse(localStorage.getItem('languages'));

    if (langFromLocalStore !== undefined && langFromLocalStore.length > 0) {
      languageGuid = langFromLocalStore.find(language => language.Code === currentLanguageCode).LanguageId;
    }

    return languageGuid;
  }

  const getCountries = useCallback(async () => {
    let countryResult = [];

    try {
      setCountryLoading(true);
      let currentLanguageCode = `${language}`;
      let languageGuid = await getLanguageGuidByCode(currentLanguageCode);
      let response = await getCountryData(languageGuid);
      countryResult = response.data.getCountries.map(value => {
        return { value: value.GroupGuid, display: value.Name, isoCode: value.ISOCode }
      });
      countryResult.sort((a, b) => (a.display > b.display) ? 1 : -1);
      setCountryList(countryResult);
      setCountryLoading(false);
      return countryResult;
    } catch (error) {
      setCountryLoading(false);
    }
  }, [language]);

  useEffect(() => {
    let isApiSubscribed = true;
    let countryList = [];

    getCountries()
      .then(async (res) => {
        countryList = res;
        fetch(GEOLOCATION_URL)
          .then(data => data.json())
          .then(json => {
            if (isApiSubscribed) {
              let geoLocation = json;
              if (isApiSubscribed) {

                let countryCode = geoLocation.country_code;
                let countryPhoneCode = geoLocation.country_calling_code;
                let value = { ...initialFormValue };

                if (countryList.length > 0) {
                  let selectedCountryList = countryList.filter((obj) => {
                    return obj.isoCode === countryCode
                  });

                  if (selectedCountryList.length > 0) {
                    let selectedCountryGuid = selectedCountryList[0].value;
                    setGeoLocationCountryGuid(selectedCountryGuid);
                    setGeoLocationCountryCode(countryPhoneCode);
                    setDialCode(countryPhoneCode);
                    value['countryGuid'] = selectedCountryGuid;
                  }
                }

                value['mobileNo'] = countryPhoneCode;
                setformValues(value);
              }
            }
          })
          .catch(err => {});
      }).catch(err => {
       
      });
    return () => {
      isApiSubscribed = false;
    };
  }, [getCountries]);

  const handleChange = (event) => {
    const value = { ...formValues };
    value[event.target.name] = event.target.value;
    setformValues(value);
  }

  const clearAlertType = () => {
    setAlertMessage("");
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    clearAlertType();
    setFreeTrialDataSubmited(false);
    let currentLanguageCode = `${language}`;
    let employee_id = localStorage.getItem('e_id');
    let e_id = employee_id == null ? 0 : parseInt(employee_id);
    let campaign_id = localStorage.getItem('c_id');
    let c_id = campaign_id == null ? 0 : parseInt(campaign_id);

    let inputData = {
      "fullName": formValues.fullName,
      "companyName": formValues.companyName,
      "email": formValues.email,
      "countryGuid": formValues.countryGuid,
      "phone_number": formValues.mobileNo,
      "entity_type": EventTypeEnum.BUY_LICENCE,
      "empl_id": e_id,
      "campaign_id": c_id
    }


    let isValid = await validateInputData(formValues);

    if (isValid) {
      if (!checked) {
        setAlertMessage("You must agree with terms and conditions.");
        setAlertModelOpen(true);
      }
      else {
        setLoading(true);

        let checkEmailAlreadyRegistered = await getRegisteredUserByEmail(formValues.email);

        if (checkEmailAlreadyRegistered.length > 0) {
          let validationMessage = await getMessages(MESSAGE.EMAIL_ALREADY_REGISTERED, currentLanguageCode);
          setAlertMessage(validationMessage);
          setAlertModelOpen(true);
          setLoading(false);
        } else {
          createTrialUserInfo(inputData).then(res => {
            setFreeTrialDataSubmited(true);
            setLoading(false);
            setAlertMessage(res.data.returnResponse);
            setAlertModelOpen(true);
          }).catch((err) => {
            setLoading(false);
            if (err.response) {
              let errMessage = err.response.data.errorMessage;
              setAlertMessage(errMessage);
              setAlertModelOpen(true);
            }
          });
        }
      }
    }
  }

  const handleModalOpen = event => {
    setModelOpen(true);
  }

  const handleModalClose = event => {
    setModelOpen(false);
  }

  const handleCheck = () => {
    setCheckBoxValue(!checked);
  }

  const validateInputData = async (formValues) => {
    let currentLanguageCode = `${language}`;
    let validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let inValidList = [];
    let bCanContinue = true;

    if (formValues.fullName === null || formValues.fullName === "" || formValues.fullName === undefined) {
      inValidList.push(FIELD_NAME.FULL_NAME);
      bCanContinue = false;
    }

    if (formValues.companyName === null || formValues.companyName === "" || formValues.companyName === undefined) {
      inValidList.push(FIELD_NAME.COMPANY_NAME);
      bCanContinue = false;
    }

    if (formValues.email === null || formValues.email === "" || formValues.email === undefined) {
      inValidList.push(FIELD_NAME.EMAIL);
      bCanContinue = false;
    }

    if (formValues.countryGuid === null || formValues.countryGuid === "" || formValues.countryGuid === undefined || formValues.countryGuid === uuid.NIL) {
      inValidList.push(FIELD_NAME.COUNTRY);
      bCanContinue = false;
    }

    if (formValues.mobileNo === null || formValues.mobileNo === "" || formValues.mobileNo === undefined) {
      inValidList.push(FIELD_NAME.MOBILE_NUMBER);
      bCanContinue = false;
    }

    if (bCanContinue === false) {
      let validationResponse = await getFieldConsoleMessages(MESSAGE.VALIDATION_EXCEPTION, currentLanguageCode, inValidList);
      setAlertMessage(validationResponse);
      setAlertModelOpen(true);
    }
    else {
      if (validEmailRegex.test(formValues.email) === false) {
        bCanContinue = false;
        let email = formValues.email;
        let valueFields = { V1: email };
        let validationMessage = await getValueMessages(MESSAGE.INVALID_EMAIL_MESSAGE, currentLanguageCode, JSON.stringify(valueFields));
        setAlertMessage(validationMessage);
        setAlertModelOpen(true);
      } else if (formValues.mobileNo === selected_dial_code) {
        bCanContinue = false;
        let validationMessage = await getMessages(MESSAGE.INVALID_MOBILE_NUMBER_MESSAGE, currentLanguageCode);
        setAlertMessage(validationMessage);
        setAlertModelOpen(true);
      }
    }

    return bCanContinue;
  }

  const getCountryData = (languageGuid) => {
    return new Promise((resolve, reject) => {
      getCountry(languageGuid).then(data => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  const phoneInputOnChange = (value, data, event, formattedValue) => {
    const formData = { ...formValues };
    formData['mobileNo'] = '+' + value;
    setformValues(formData);
    let selected_dial_code = '+' + data.dialCode;
    setDialCode(selected_dial_code);
  };


  return (
    <div>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
          }}
        />
        <meta charSet="utf-8" />
        <title>Buy License - Logipulse Software Purchase</title>

        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Try Logipulse software solutions and experience impeccable logistics management from a single platform. Transform your logistics operations to the next level with Logipulse solutions.")}
        />
        <meta
          name="Keywords"
          content={t("Logistics software purchase, buy logistics solutions")}
          data-react-helmet="true"
        />
      </Helmet>
      <main className="purchase-page freetrial_page_main" lang={language}>
        <div className="purchase-page-div freetrial_page_main_div" lang={language}>
          <div className="container">

            <div className="row purchase_freetrial freetrial_content_row_div padding_tb_45" lang={language}>
              <div className="col-md-6">
                <div className="contents purchasecontents">
                  <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                  <p><Trans>Here starts your novel opportunity to lead the competition.</Trans><Trans>Logipulse renders you a digital transformation to your Logistics operation.</Trans><Trans>Spread out your wings around the world and manage them from a single platform.</Trans><Trans>Head out of adversity!</Trans></p>
                  <Link to="/login/" className="sign-up signpurchase getsign"><Trans>Sign In</Trans></Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contents">

                  <div className="purchase_heading_section">
                    <div className="profile"><b><Trans>Sign Up</Trans></b></div>
                    <div className="purchase_progress">
                      <ul id="progressbar_purchase">
                        {/* <li className="active"></li> */}
                        {/* <li></li> */}
                      </ul>
                    </div>
                  </div>

                  <fieldset disabled={isLoading}>
                    <Form onSubmit={submitHandler} autoComplete="off">
                      <Form.Group controlId="formFullName" >
                        <Form.Label><Trans>Full Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <Form.Control type="text" name='fullName' value={formValues.fullName}
                          onChange={handleChange} autoFocus required />
                      </Form.Group>


                      <Form.Group controlId="formCompany">
                        <Form.Label><Trans>Company Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <Form.Control type="text" name="companyName" value={formValues.companyName}
                          onChange={handleChange} required />
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1" lang={language}>
                        {isLoadingCountry && (
                          <Photo src="transparent-loader.png" className="select_control_loader" alt="" />
                        )}
                        <Form.Label><Trans>Country</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <Form.Control as="select" required name="countryGuid" value={formValues.countryGuid} onChange={handleChange} lang={language}>
                          {countryList && countryList.map((elmt) => <option key={elmt.value} value={elmt.value}>{t(elmt.display)}</option>)}
                        </Form.Control>
                      </Form.Group>

                      <div className="margin_bottom_16">
                        <Form.Label className="phone_input_label"><Trans>Mobile Number</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <PhoneInput
                          country={'us'}
                          containerClass="my-container-class"
                          inputClass="phone_input_class"
                          required="true"
                          enableSearch="true"
                          value={formValues.mobileNo}
                          onChange={phoneInputOnChange}
                        /></div>

                      <Form.Group controlId="email" >
                        <Form.Label><Trans>Email</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <Form.Control
                          type="email" name="email"
                          value={formValues.email}
                          placeholder={t("Enter your Email")}
                          onChange={handleChange} required />
                      </Form.Group>

                      <div id="main" lang={language}>
                        <div className="form-group form-check terms_condition_button" lang={language}>
                          <div className="chkbox-agree-section" lang={language}>
                            <input type="checkbox" className="form-check-inputs" onChange={handleCheck} defaultChecked={checked} />

                            <div className="agree-section" lang={language}>
                              <span><Trans>I agree</Trans> <Link to="#" onClick={handleModalOpen}><Trans>Terms & Conditions</Trans></Link></span>
                            </div>
                          </div>
                          <div className="align-btn">
                            <Form.Group className="next_button_align">
                              <button type="submit" className="trial_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading}><Trans>Submit</Trans>
                                {
                                  isLoading ? (
                                    <Photo src="loader_64.gif" className="spinner" alt="" />
                                  ) : null
                                }
                              </button>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </fieldset>

                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={customStyles} className="modal_padding"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <div className="modal-header">
                      <h5 className="modal-title ml-0">Terms & Conditions</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleModalClose}>
                        <span aria-hidden="true" className="imges"> <Photo src="close-black.svg" alt="" /></span>
                      </button>
                    </div>
                    <div className="modal-body text-left content_scroll height_400">
                      <div className="termscondition"><b>USE OF SITE</b></div>
                      <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                      <div className="termscondition"><b>ACCESS AND INTERFERENCE</b></div>
                      <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                      <div className="termscondition"><b>NON-DISCLOSURE</b></div>
                      <div className="termscondition_content">Unless otherwise agreed, each contracting party will treat any information (including documentation, samples etc.) disclosed to it by STELLOSYS as a trade secret, use such information solely for the purpose of the Contract, and refrain from disclosing it to any third parties.</div>
                      <div className="termscondition"><b>LIMITATION OF LIABILITY</b></div>
                      <div className="termscondition_content">Except for actions or claims arising from the gross negligence or intentional or wilful misconduct, liability to STELLOSYS shall be limited to the compensation fees based upon the contract.</div>
                      <div className="termscondition"><b>LINKS TO THIRD PARTY SITES</b></div>
                      <div className="termscondition_content">STELLOSYS does not review or control and is thus not responsible for any third-party sites or their content. Such links should not be assumed to constitute an endorsement or recommendation by STELLOSYS. By clicking on any of these links, you are leaving the STELLOSYS Site and accessing these other sites at your own risk.</div>

                      <div className="termscondition"><b>COMMUNITY FORUMS</b></div>
                      <div className="termscondition_content">STELLOSYS may provide chat room, bulletin boards, blogs or other user forums (“Forums”) that allow third parties to post content and comments. STELLOSYS has the right, but not the obligation, to review or monitor third postings (“Submissions”) and to remove Submissions that contains material STELLOSYS deems offensive, abusive, defamatory, obscene or otherwise unacceptable. However, STELLOSYS shall not be liable for claims arising from those Submissions whether arising under the laws of copyright, libel, privacy, obscenity or otherwise.</div>

                      <div className="termscondition"><b>GOVERNING LAW</b></div>
                      <div className="termscondition_content">These Terms shall be governed in all respects by the laws of the <b>The Cybersecurity Framework (NCFS)</b></div>

                      <div className="termscondition"><b>IMPORT/EXPORT</b></div>
                      <div className="termscondition_content">You may not access, download, use, or export information (including software, products or services) contained on this Site in violation of any applicable laws or regulations including, but not limited to the export laws of the European Union and United States.</div>

                      <div className="termscondition"><b>PRIVACY</b></div>
                      <div className="termscondition_content">For information on how STELLOSYS handles your personal information, see our Privacy Policy.</div>

                      <div className="termscondition"><b>INDEMNIFICATION</b></div>
                      <div className="termscondition_content">You agree to indemnify, defend and hold harmless, STELLOSYS, its subsidiaries, affiliates, joint ventures, business partners, licensors, employees, agents and third-party information providers from and against all losses, expenses, damages and costs, including attorney fees, resulting from any violation of these Terms (including negligent or wrongful conduct) by you or your use and access to the Site.</div>



                    </div>
                    <div className="modal-footer">
                      <div className="learnmore"><FontAwesomeIcon icon={faExclamationCircle} /><span className="learnmore-text">Learn more</span></div>
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleModalClose}>I AGREED!</button>
                    </div>

                  </Modal >

                  {/* Modal Start: - Alert modal */}
                  <Modal
                    isOpen={isAlertModalOpen}
                    onRequestClose={handleAlertModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={alertBoxStyle} className="modal_padding"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <div className="modal-body">
                      <div className="termscondition_content pb-0">{alert_message}</div>
                    </div>
                    <div className="modal-footer text_align_center pl-0 pr-0 mt-0 justify_space_around">
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleAlertModalClose}>OK</button>
                    </div>

                  </Modal >
                  {/* Modal End: - Alert modal */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="purchase-footer">
          <div className="purchase-footer-bottom">
            <div className="container">
              <div className="row footer_row_editor align-content-center">
                <div className="col-md-6 power">
                  <div className="powered_by_font_style"><Trans>Powered by</Trans></div>
                  <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                </div>
                <div className="col-md-6 copyright copyright_flex_align">
                  <div className="copyright_font_style">
                    <Trans>Copyright 2021 Stellosys</Trans><br /> <Trans>All rights Reserved.</Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  )
}

export default Purchase;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
